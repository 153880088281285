@import "./theme/typography.scss";

html {
  font-size: 16px;
  line-height: 1.5rem;
}

a {
  text-decoration: none;
}

body {
  font-family: $font-sans-serif;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3 {
  text-align: center;
}
