.debounce-and-throttle__events {
  margin: 1rem 0;
  min-height: 4rem;
  word-wrap: break-word;
}

.debounce-and-throttle__zone {
  align-items: center;
  background-color: lightgrey;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  padding: 50px 0;
  text-align: center;
}
