@import url(https://fonts.googleapis.com/css?family=Josefin+Sans:300,400,600);
@import url(https://fonts.googleapis.com/css?family=Josefin+Sans:300,400,600);
.app {
  margin: auto;
  max-width: 980px;
  padding: 20px; }

.debounce-and-throttle__events {
  margin: 1rem 0;
  min-height: 4rem;
  word-wrap: break-word; }

.debounce-and-throttle__zone {
  align-items: center;
  background-color: lightgrey;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  padding: 50px 0;
  text-align: center; }

.header {
  text-align: center; }

.home__row {
  margin: 10px 0;
  text-align: center; }

html {
  font-size: 16px;
  line-height: 1.5rem; }

a {
  text-decoration: none; }

body {
  font-family: "Josefin Sans", sans-serif;
  margin: 0;
  padding: 0; }

h1,
h2,
h3 {
  text-align: center; }

